import React, { useState } from 'react';
import { NextPage } from 'next';
import { Container, Paper } from '@mui/material';
import { ResponsiveContainer } from '@/styles';
import { StyledBreadcrumbs } from '@/components';
import { Login } from '@/components';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { RegistrationLayout } from '@/components';
import type { ReactElement } from 'react';
import { VerifyEmail } from 'src/ui/components/smart/login/verify-email';
import { useLogin } from '@/zustand';
import { sm } from 'src/site-map';

type Props = {};

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale)),
    },
  };
}

const Page: NextPage<Props> = ({}) => {
  const needsVerification = useLogin(state => state.needsVerification);
  const contentComponent = () => {
    return needsVerification ? <VerifyEmail></VerifyEmail>  : <Login></Login>
  }

  return (
    <ResponsiveContainer sx={{ mb: 8, minHeight: 500 }}>
      <StyledBreadcrumbs alias={sm.login.url}/>
      <Container sx={{ mt: 4, p: '0px !important' }} maxWidth='sm'>
        <Paper elevation={4} sx={{ p: [2, 3, 4] }}>
          {contentComponent()}
        </Paper>
      </Container>
    </ResponsiveContainer>
  );
};

Page.getLayout = function getLayout(page: ReactElement) {
  return <RegistrationLayout>{page}</RegistrationLayout>;
};

export default Page;
